import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";

const bg = require("../../images/background/bg3.png");
// style="background-image:url(images/background/bg3.png); background-size: cover;"

const FooterHome = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial state

  // Update the isMobile state on window resize
  const updateWindowDimensions = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    // Add event listener to handle resize
    window.addEventListener("resize", updateWindowDimensions);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    //emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
    emailjs
      .sendForm(
        "YOUR_SERVICE_ID",
        "YOUR_TEMPLATE_ID",
        e.target,
        "d9b2e0f5fc72cb94792110e8ff2028f3-us16"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <>
      <footer className="site-footer" id="footer">
        <div className="footer-top-custom">
          <div className="cielx-new-container" style={{ margin: "0px auto" }}>
            <div style={{ display: "flex", justifyContent: "center", marginBottom: 35 }}>
              <img src={require("../../images/background/logo-footer.png")} style={{ width: 200 }} />
            </div>

            <div className="row">
              <div className="col-lg-3">
                <div className={isMobile ? "single-footer-column4" : "single-footer-column"} style={{ marginTop: 40 }}>

                  <div className="icon-text-container" style={{ justifyContent: "center", marginBottom: 34 }}>
                    <div style={{ width: 24, height: 24 }}><img src={require("../../images/background/bangladesh.png")} /></div>
                    <div><a href="#footer">Bangladesh</a></div>
                  </div>


                  <div className="icon-text-container">
                    <div style={{ width: 15, height: 27 }}><img src={require("../../images/background/smallPhone.png")} /></div>
                    <div><a href="tel:+8801717855488" >+880 1717-855488</a></div>
                  </div>


                  <div className="icon-text-container">
                    <div style={{ width: 24, height: 24 }}><img src={require("../../images/background/smallMail.png")} /></div>
                    <div><a href="mailto:fuad@cielx.org">fuad@cielx.org</a></div>
                  </div>


                  <div className="icon-text-container">
                    <img src={require("../../images/background/smallLocation2.png")} width={24} height={24} />
                    <a href="https://www.google.com/maps/place/23%C2%B050'18.0%22N+90%C2%B022'34.0%22E/@23.8383333,90.3761111,17z/data=!3m1!4b1!4m4!3m3!8m2!3d23.8383333!4d90.3761111?entry=ttu&g_ep=EgoyMDI0MDgyNi4wIKXMDSoASAFQAw%3D%3D" >
                      House 996 (1st floor), Road: 9/A, Avenue: 11, Mirpur DOHS,
                      Pallabi, Dhaka- 1216, Bangladesh.
                    </a>
                  </div>
                </div>


              </div>

              <div className="col-lg-3">
                <div className={isMobile ? "single-footer-column4" : "single-footer-column"} style={{ marginTop: 40 }}>

                  <div className="icon-text-container" style={{ justifyContent: "center", marginBottom: 34 }}>
                    <div style={{ width: 24, height: 24 }}><img src={require("../../images/background/china.png")} /></div>
                    <div><a href="#footer">China</a></div>
                  </div>


                  <div className="icon-text-container">
                    <div style={{ width: 15, height: 27 }}><img src={require("../../images/background/smallPhone.png")} /></div>
                    <div><a href="tel:+13472598967" >+13472598967</a></div>
                  </div>


                  <div className="icon-text-container">
                    <div style={{ width: 24, height: 24 }}><img src={require("../../images/background/smallMail.png")} /></div>
                    <div><a href="mailto:info@cielx.org">info@cielx.org</a></div>
                  </div>


                  <div className="icon-text-container">
                    <img src={require("../../images/background/smallLocation2.png")} width={24} height={24} />
                    <a href="https://maps.app.goo.gl/ZyLQQKsRrJCG2Xga8" >
                      Room 1024, Building 3, Kunhua International, 58 Hengshan Road, Kunshan Development Zone, Jiangsu Province
                    </a>
                  </div>
                </div>


              </div>

              <div className="col-lg-3">
                <div className={isMobile ? "single-footer-column4" : "single-footer-column"} style={{ marginTop: 40 }}>

                  <div className="icon-text-container" style={{ justifyContent: "center", marginBottom: 34 }}>
                    <div style={{ width: 24, height: 24 }}><img src={require("../../images/background/india.png")} /></div>
                    <div><a href="#footer">India</a></div>
                  </div>


                  <div className="icon-text-container">
                    <div style={{ width: 15, height: 27 }}><img src={require("../../images/background/smallPhone.png")} /></div>
                    <div><a href="tel:+919500450905 " >+91 9500450905 </a></div>
                  </div>


                  <div className="icon-text-container">
                    <div style={{ width: 24, height: 24 }}><img src={require("../../images/background/smallMail.png")} /></div>
                    <div><a href="mailto:srinivasan@cielx.org">srinivasan@cielx.org</a></div>
                  </div>


                  <div className="icon-text-container" style={{ paddingBottom: 30 }}>
                    <img src={require("../../images/background/smallLocation2.png")} width={24} height={24} />
                    <a href="https://maps.app.goo.gl/ytkhv83FZKkFLoLM7" >
                      No:165, Mitta IRIS, Kothur-TVS Road, Hosur - 635126, Tamilnadu, India.
                    </a>
                  </div>
                </div>


              </div>

              <div className="col-lg-3" >
                <div className="single-footer-column4" style={{ marginTop: 40 }}>

                  <div className="icon-text-container" style={{ justifyContent: "center", marginBottom: 34 }}>
                    <div style={{ width: 24, height: 24 }}><img src={require("../../images/background/pakistan.png")} /></div>
                    <div><a href="#footer">Pakistan</a></div>
                  </div>


                  <div className="icon-text-container">
                    <div style={{ width: 15, height: 27 }}><img src={require("../../images/background/smallPhone.png")} /></div>
                    <div><a href="tel:+923008444769" >+923008444769</a></div>
                  </div>


                  <div className="icon-text-container">
                    <div style={{ width: 24, height: 24 }}><img src={require("../../images/background/smallMail.png")} /></div>
                    <div><a href="mailto:info@cielx.org">info@cielx.org</a></div>
                  </div>


                  <div className="icon-text-container">
                    <img src={require("../../images/background/smallLocation2.png")} width={24} height={24} />
                    <a href="https://maps.app.goo.gl/reZ4jHLFhXyeZdWk9" >
                      Address: 18-km, Ferozpur Road, Lahore, Pakistan
                    </a>
                  </div>
                </div>


              </div>

            </div>
          </div>
        </div>

        <div className="footer-bottom" style={{ height: 54, display: "flex", alignItems: "center" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 text-center">
                {" "}
                <span className="footer-last-text">2024 by CIELX, All rights reserved.</span>{" "}
              </div>
              {/* <div className="col-md-6 col-sm-6 text-right ">
                {" "}
                <span className="footer-last-text">Developed by Sweet iTech Team</span>{" "}
              </div> */}
              {/* <div className="col-md-6 col-sm-6 text-right ">
                <div className="widget-link ">
                  <ul>
                    <li>
                      <Link to=""> About</Link>
                    </li>
                    <li>
                      <Link to=""> Help Desk</Link>
                    </li>
                    <li>
                      <Link to=""> Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default FooterHome;
