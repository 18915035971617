import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import Sticky from "react-stickynode";
import { Typeahead } from 'react-bootstrap-typeahead';
import { Button, Form } from "react-bootstrap";

const Header10 = () => {
  const naviconRef = useRef(null);
  const headerNavRef = useRef(null);
  const [selected, setSelected] = useState([]);
  const [searchInput, setSearchInput] = useState(false);
  const location = useLocation();  // Get current location
  const history = useHistory();    // Get history object for navigation

  const options = [
    { page: 'Home', link: '' },
    { page: 'About Us', link: 'about-us' },
    { page: 'Growth Towards Nature', link: 'growthTowardsNature' },
    { page: 'Products & Services', link: 'all-products' },
    { page: 'Future', link: 'future' },
    { page: 'Testimonials', link: 'testimonials' },
    { page: 'Contact Us', link: 'contact-us' },
    { page: 'Certificates', link: 'certificate-details' },
  ];

  useEffect(() => {
    const btn = naviconRef.current;
    const nav = headerNavRef.current;

    const toggleFunc = () => {
      btn.classList.toggle("open");
      nav.classList.toggle("show");
    };

    btn.addEventListener("click", toggleFunc);

    return () => {
      btn.removeEventListener("click", toggleFunc);
    };
  }, []);

  const handleSearchSubmit = () => {
    if (selected.length > 0) {
      const selectedOption = selected[0];
      const link = selectedOption.link ? `/${selectedOption.link}` : '/';
      history.push(link);
    }
  };

  useEffect(() => {
    handleSearchSubmit();
  }, [selected]);

  // Helper function to determine if the link is active
  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <>
      <header className="site-header mo-left header-transparent overlay header navstyle4">
        <div className="top-bar">
          <div className="container">
            <div className="row d-flex justify-content-between align-items-center">
              <div className="dlab-topbar-left">
                <ul></ul>
              </div>
            </div>
          </div>
        </div>

        <Sticky innerZ={999} enabled={true}>
          <div className="sticky-header main-bar-wraper navbar-expand-lg">
            <div className="main-bar clearfix ">
              <div className="container clearfix">
                <div className="logo-header mostion">
                  <Link to="/">
                    <img src={require("../../images/logo2.png")} alt="" />
                  </Link>
                </div>

                <button
                  className="navbar-toggler collapsed navicon justify-content-end"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  ref={naviconRef}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>

                <div className="extra-nav">
                  <div className="extra-cell">
                    <Form inline>
                      <div style={{ display: 'flex', zIndex: '0' }}>
                        {searchInput ? (
                          <>
                            <Typeahead
                              id="basic-example"
                              labelKey={(option) => `${option.page}`}
                              onChange={setSelected}
                              options={options}
                              placeholder="Search A Page.."
                              selected={selected}
                              minLength={1}
                            />
                            <Button
                              onClick={() => { setSearchInput(!searchInput); }}
                              id="quik-search-btn"
                              type="button"
                              className="site-button-link"
                            >
                              <i className="la la-search"></i>
                            </Button>
                          </>
                        ) : null}
                      </div>
                    </Form>
                  </div>
                </div>

                <div className="extra-nav">
                  <div className="extra-cell">
                    <Form inline>
                      <div style={{ display: 'flex', zIndex: '0' }}>
                        {!searchInput ? (
                          <Button
                            onClick={() => { setSearchInput(!searchInput); }}
                            id="quik-search-btn"
                            type="button"
                            className="site-button-link"
                          >
                            <i className="la la-search"></i>
                          </Button>
                        ) : null}
                      </div>
                    </Form>
                  </div>
                </div>

                <div
                  className="header-nav navbar-collapse collapse justify-content-end"
                  id="navbarNavDropdown"
                  ref={headerNavRef}
                >
                  <ul className="nav navbar-nav">
                    {/* <li className={`has-mega-menu homedemo ${isActive('/') ? 'active' : ''}`}>
                      <Link to="/" style={isActive('/') ? { color: '#00aeef', fontSize: 20 } : { fontSize: 20 }}>Home</Link>
                    </li> */}
                    <li className={isActive('/about-us') ? 'active' : ''}>
                      <Link to="/about-us" style={isActive('/about-us') ? { color: '#00aeef', fontSize: 20 } : { fontSize: 20 }}>About Us</Link>
                    </li>
                    <li className={isActive('/growthTowardsNature') ? 'active' : ''}>
                      <Link to="/growthTowardsNature" style={isActive('/growthTowardsNature') ? { color: '#00aeef', fontSize: 20 } : { fontSize: 20 }}>
                        Growth Towards Nature
                      </Link>
                    </li>
                    <li className={`has-mega-menu ${isActive('/all-products') ? 'active' : ''}`}>
                      <Link to="/all-products" style={isActive('/all-products') ? { color: '#00aeef', fontSize: 20 } : { fontSize: 20 }}>
                        Products & Services
                      </Link>
                    </li>
                    <li className={isActive('/future') ? 'active' : ''}>
                      <Link to="/future" style={isActive('/future') ? { color: '#00aeef', fontSize: 20 } : { fontSize: 20 }}>Future</Link>
                    </li>
                    <li className={isActive('/testimonials') ? 'active' : ''}>
                      <Link to="/testimonials" style={isActive('/testimonials') ? { color: '#00aeef', fontSize: 20 } : { fontSize: 20 }}>Testimonials</Link>
                    </li>
                    <li className={isActive('/contact-us') ? 'active' : ''}>
                      <Link to="/contact-us" style={isActive('/contact-us') ? { color: '#00aeef', fontSize: 20 } : { fontSize: 20 }}>
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </Sticky>
      </header>
    </>
  );
};

export default Header10;
